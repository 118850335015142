// src/lang/index.js
import { createI18n } from 'vue-i18n';
import elementLangEn from 'element-plus/es/locale/lang/en';
import elementLangZhCn from 'element-plus/es/locale/lang/zh-cn';
import localeLangEn from './en';
import localeLangZhCn from './zh-cn';

const messages = {
    en: {
        ...localeLangEn,
        ...elementLangEn,
    },
    'zh-cn': {
        ...localeLangZhCn,
        ...elementLangZhCn,
    },
};
const i18n = createI18n({
    locale: localStorage.getItem('lang') || 'en', //zh-cn
    messages,
});

export default i18n;