import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import "@/router/permission"
import piniaPersist from 'pinia-plugin-persistedstate'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
import 'windi.css'
import 'virtual:windi.css';
// import 'virtual:svg-icons-register';
import i18n from './lang/index';
import SvgIcon from './components/SvgIcon/index'// svg component
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus, {
    i18n : (key: any, value: any) => i18n.t(key, value)
})
.component("svg-icon", SvgIcon)
.use(i18n)
.use(pinia)
.use(router)
.mount("#app");