export default {
  all:'全部',
  common: {
    noData:"暂无数据...",
    back: '返回',
    ok: '确定',
    cancel: '取消',
    prevLabel: '上一步',
    nextLabel: '下一步',
    logOut:'退出登录',
    modifyPassword: "修改密码",
    pleaseEnter:"请输入",
    pleaseSelect:"请选择",
    required:"不能为空",
    incorrectFormat:"格式不正确",
    operate:"操作",
    edit:"编辑",
    submit:"确认",
    clear:"清空",
    delete:"删除",
    startDate:"开始日期",
    endDate:"结束日期",
    productNo:"产品编号",
    productName:"产品名称",
    agent:"代理商",
    departureDate:"出发日期",
    departureCity:"出发城市",
    arrivePort:"到达城市",
    viaCity:"途径城市",
    productInformation:"产品信息",
    contractSheet:"签约单",
    contractSheetStatus:"签约单状态",
    type:"类型",
    price: "价格",
    price1: "金额",
    travelDays: "出游天数",
    payment:'支付',
    remark:"备注",
    perPerson:"人",
    confirmationSheet: "确认单",
    refresh: "刷新",
  },
  login:{
    login:"登录",
    password:"8个字符以上",
    emailValidate:"邮箱格式不正确",
    passwordValidate:"密码格式不正确，请输入8个字符以上",
    tips: "还没有账号？",
    register: "去注册",
    forgetPassword: "忘记密码",
    emailPlaceholder: "请输入邮箱",
    passwordPlaceholder: "请输入密码",
  },
  forgetPassword: {
    title: "忘记密码",
    tips: "输入您的账户关联的电子邮件地址",
    codePlaceholder: "请输入收到的验证码",
    send: "确认",
    getCode: "获取验证码",
    resend: "重新发送",
    originalPassword: "原始密码",
    newPassword: "设置新密码",
    confirmPassword: "确认新密码",
    originalPasswordPlaceholder: "请输入原始密码",
    newPasswordPlaceholder: "请输入新密码",
    confirmPasswordPlaceholder: "请再次输入新密码",
    confirmPasswordTips1: "新密码两次输入不一致",
    confirmPasswordTips2: "新密码与原始密码相同",
    sending: "发送中...",
  },
  register: {
    step1: "填写注册信息",
    step2: "填写入驻申请",
    step3: "入驻审核",
    title1: "账号信息",
    title2: "公司信息",
    title3: "联系人信息",
    username: "用户名",
    lastNamePlaceholder: "请输入姓",
    middleNamePlaceholder: "请输入中间名",
    firstNamePlaceholder: "请输入名",
    email: "注册邮箱",
    code: "邮箱验证码",
    password: "密码",
    passwordPlaceholder: "8个字符以上",
    companyName: "企业全称",
    companyShortName: "企业简称",
    companyAddress: "企业办公地址",
    qualifications: "企业资质认证",
    qualificationsImage: "上传资质证明",
    mainBusiness: "主营业务",
    companyNamePlaceholder: "请输入企业全称",
    companyShortNamePlaceholder: "请输入企业简称",
    companyAddressPlaceholder: "请输入企业办公地址",
    qualificationsPlaceholder: "请选择企业资质认证",
    qualificationsImagePlaceholder: "请上传资质证明",
    mainBusinessPlaceholder: "请输入主营业务",
    tourismOrganization: "旅游组织",
    alliance: "税号",
    contactName: "联系人姓名",
    firstName: "名",
    lastName: "姓",
    contactEmail: "联系人邮箱",
    contactPhone: "联系人电话",
    areaCode: "区号",
    emailPlaceholder: "请输入联系人邮箱",
    areaCodePlaceholder: "请选择区号",
    contactPhonePlaceholder: "请输入联系人电话",
    tips: "仅支持pdf及jpg、jpeg格式的文件，大小控制在0-50MB",
    submit: "提交申请",
    headline: "尊敬的代理商",
    reason: "驳回原因",
    revise: "重新修改",
    viewNow: "立即查看",
    auditTips1: "您的入驻申请已经成功提交，我司工作人员会在24小时内通过邮箱的形式通知您审核结果，请您耐心等待，谢谢！",
    auditTips2: "请您根据驳回原因重新发起入驻申请，谢谢！",
    approvalFailed: "您的入驻申请审核未通过。"
  },
  router:{
    lineProducts:'线路产品',
    productOrders:'产品订单',
    productDetail:'产品详情',
    productBooking:'产品预定',
    orderDetail:"订单信息",
    operationLog:"操作记录",
    oderPayment:'订单支付',
  },
  orderCenter:{
    orderID:"订单编号",
    orderInformation:"下单信息",
    orderInformationPlaceholder:"联系人姓名、邮箱",
    responsibleSales:"责任销售",
    responsibleSalesPlaceholder:"责任销售姓名、邮箱",
    orderTime:"下单时间",
    touristName:"游客姓名",
    signingTime:"签约时间",
    backTime:"回团时间",
    orderStatus:"订单状态",
    paymentStatus:"付款状态",
    ToBeConfirmed:"待确认",
    confirmed:"已确认",
    toBeSigned:"待签约",
    signed:"已签约",
    waitingToTravel:"待出游",
    traveling:"已出游",
    cancelled:"已取消",
    done:"已回团",
    unpaid:"未付款", //支付状态
    partialPayment:"部分付款",
    fullyPaid:"已付全款",
    numberOfTravelers:"出游人数",
    adults:"成人",
    children:"儿童",
    senior:"老人",
    orderAmount:"订单金额",
    orderContact:"订单联系人",
    amountReceivable:"应收",//* */
    amountReceived:"已收",
    unpaidAmount:"未收",
    viewDetails:"查看详情",
    confirmSigning:"确认签约",
    orderPayment:"订单支付",
    packageType:"套餐类型",
    orderInformation1:"订单信息",
    agent:"代理商",
    contactName:"联系人姓名",
    contactEmail:"联系人邮箱",
    resourceInformation:"资源信息", 
    orderRemarks:"订单备注",
    paymentRecord:"付款记录",
    priceDetails:"价格明细",
    touristInformation:"游客信息",
    priceType:"价格类型",
    count:"数量",
    totalPrice:"总价",
    totalCommission:"佣金总计",
    addANote:"添加备注",
    remarks:"备注内容",
    filledBy:"填写人",
    fillInTime:"填写时间",
    TotalAmountPaid:"已付总金额",
    paymentAmount:"付款金额",
    paymentTime:"付款时间",
    receiptCompany:"到账公司",
    paymentOrderNumber:"付款单号",
    status:"状态",
    subscriberTime:"认款人/时间",
    totalPayableAmount:"应付总金额",
    amountDetails:"金额明细",
    serialNumber:"序号",
    surnameFirstName:"姓名",
    gender:"性别",
    male:"男",
    female:"女",
    email:"邮箱",
    phoneNumber:"手机号",
    nationality:"国籍",
    passportNo:"护照号",
    passportValidity:"护照截止日期",
    birthDate:"出生日期",
    paymentRules:"付款规则",
    deposit:"定金",
    final:"尾款",
    fullPayment:"全款",
    paymentDeadline:"付款截止时间",

    confirmSheetStatus:{
      toBeSent:"待发送",
      toBeSigned:"待签约",
      signed:"已签约"
    },
    //订单支付页面
    remittanceAmount:"汇款金额",
    dollar:"美元",
    currentOrderPayable:"当前订单应支付",
    canBeDividedInto: "可分为",
    payments: "次支付",
    paid:"已支付",
    notPaid:"未支付",
    minimumPayment:"本次最低支付",
    remittanceInformation:"汇款信息",
    withinTheUnitedStates: "美国境内",
    copyRemittanceInformation:"复制汇款信息",
    routingNumber: "路由号码",
    accountName: "账户名称",
    payeesAddress: "收款人地址",
    receivingBank:"银行名称",
    receivingAccount:"收款账号",
    receivingDate:"汇款日期",
    remittanceVoucher:"汇款凭证",
    remittanceVoucherPlaceholder:"请上传汇款凭证",
    datePlaceholder:"请选择汇款时间",
    operationContent:"操作内容",//操作记录
    operationTime:"操作时间",//操作记录
    operatorEmail:"操作人/邮箱",
    payNow: "立即支付",
    cancelOrder: "取消订单",
    remainingPaymentTime: "剩余支付时间",
    reasonForCancellation: "取消原因",
    uploadTips: "请选择.pdf / .jpg / .jpeg格式的文件，文件大小控制在50MB以内",
    clickUpload: "点击上传",
    payAmountPlaceholder1: "请输入正确数字",
    payAmountPlaceholder2: "金额不能小于",
    payAmountPlaceholder3: "金额不能大于",
  },
  productCenter: {
    numberDays: "行程天数",
    productTags: "产品标签",
    selected: "已选择",
    search: "搜索",
    searchPlaceholder: "请输入产品相关信息，如名称、编号、出发城市、到达城市、途经城市、行程天数",
    showCommission: "显示佣金",
    hiddenCommission: "隐藏佣金",
    earliestDeparture: "最早出发日期",
    latestDeparture: "最晚出发日期",
    more: "更多",
    close: "收起",
    tips1: "为您匹配出",
    tips2: "条产品",
    commission: "佣金",
    share: "分享",
    checkDetails: "查看详情",
    commissionLabelHigh: "佣金 低 -> 高",
    commissionLabelLow: "佣金 高 -> 低",
    priceLabelHigh: "价格 低 -> 高",
    priceLabelLow: "价格 高 -> 低",
    dateLabelHigh: "日期 近 -> 远",
    dateLabelLow: "日期 远 -> 近",
    daysLabelHigh: "天数 少 -> 多",
    daysLabelLow: "天数 多 -> 少",
    total: "共有",
    items: "条",
    href: "链接",
    copySuccess: "复制成功",
    copyError: "复制失败",
    downText: "下载行程文件",
    from: "",
    person: "起/人",
    dateTips: "以下价格为1成人起价 ，计价可能有延迟，请以下单时为准",
    packageName: "套餐",
    stockpile: "库存",
    booking: "预订",
    basePrice: "基础价格",
    tablesLabel: {
      name: "名称",
      introduction: "简介",
      price: "价格"
    },
    adult: "成人",
    child: "儿童",
    singleRoomDifference: "单房差",
    additionalFees: "增值项目",
    features: "产品特色",
    tourIntroduction: "行程介绍",
    feeDescription: "费用说明",
    inclusiveOf: "费用包含",
    feeDoesNotInclude: "费用不含",
    bookingInformation: "预订须知",
    cancellationPolicy: "取消政策",
    kindTips: "温馨提示",
    learnMore: "了解更多",
    stow: "收起",
    breakfast: "早餐",
    lunch: "午餐",
    dinner: "晚餐",
    agentInformation: "代理商信息", 
    contactEmail: "联系人邮箱", 
    contactName: "联系人姓名",
    formLabel: {
      name: "姓名",
      firstName: "名",
      middleName: "中间名",
      lastName: "姓",
      firstNamePlaceholder: "请输入名",
      middleNamePlaceholder: "请输入中间名",
      lastNamePlaceholder: "请输入姓",
      gender: "性别",
      genderPlaceholder: "请选择性别",
      man: "男",
      woman: "女",
      transgenderMale: "跨性别男性",
      transgenderFemale: "跨性别女性",
      nonBinary: "非二元性别",
      genderqueer: "酷儿性别",
      intersex: "间性人",
      uncharted: "未知",
      email: "邮箱",
      emailPlaceholder: "请输入邮箱",
      phoneNumber: "手机号",
      phoneNumberPlaceholder1: "手机号格式不正确",
      phoneNumberPlaceholder: "请输入联系人电话",
      areaCodePlaceholder: "请选择区号",
      international: "国际",
      nationality: "国籍",
      nationalityPlaceholder: "请输入国籍名称",
      passportNo: "护照号",
      passportPlaceholder: "请输入护照号",
      passportPlaceholder1: "护照号格式不正确",
      passportValidity: "护照有效期",
      passportValidityPlaceholder: "请选择护照有效期",
      visa: "签证",
      visaPlaceholder: "是否有签证？哪个国家的？",
      birthday: "出生日期",
      birthdayPlaceholder: "请选择出生日期",
      birthdayTips1: "出生日期过大",
      birthdayTips2: "出生日期过小",
    },
    registrationDeadline: "报名截止时间",
    paymentRules: "支付规则",
    valueAddedItems: "增值项目",
    totalPrice: "总计",
    numbers: "人数",
    subtotal: "小计",
    costBreakdown: "费用明细",
    totalOrders: "订单总额",
    totalCommissions: "佣金总额",
    payable: "需支付",
    submit: "提交订单",
    next: "下一步，填写客人信息",
    showMore: "查看更多",
    checkIn: "入住",
    dialogTitle: "增值项目详情",
    lostStock1: "充足",
    lostStock2: "仅剩",
    lostStock3: "位",
    noDateTips: "当天无团期",
    secondaryConfirmation: "二次确认",
    backTip1: "返回上一步, 查看产品信息",
    backTip2: "返回上一步, 修改人数",
    bookingTip: "游客人数不能为0",
    documentation: "行程文件",
  }
};