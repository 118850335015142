export default {
  all:'All',
  common: {
    noData:"No Data",
    back: 'Back',
    ok: 'OK',
    cancel: 'Cancel',
    prevLabel: 'Prev',
    nextLabel: 'Next',
    logOut:'Logout',
    modifyPassword: "Modify Password",
    pleaseEnter:"Please Enter ",
    pleaseSelect:"Please Select ",
    incorrectFormat:" Incorrect Format",
    required:" is required",
    operate:"Operate",
    edit:"Edit",
    submit:"Submit",
    clear:"Clear",
    delete:"Delete",
    startDate:"Start Date",
    endDate:"End Date",
    productNo:"Product Number",
    productName:"Product Name",
    agent:"Agent",
    departureDate:"Departure Date",
    departureCity:"Departure City",
    arrivePort:"Arrive Port",
    viaCity:"Via City",
    productInformation:"Product Information",
    contractSheet:"Contract Sheet",
    contractSheetStatus:"Contract Sheet Status",
    type:"Type",
    price: "Price",
    price1: "Price",
    travelDays: "Travel Days",
    payment:'Payment',
    remark:"Remark",
    perPerson:"person",
    confirmationSheet: "Confirmation",
    refresh: "Refresh",
  },
  login:{
    login:"Sign in",
    password:"More than 8 characters",
    emailValidate:"The email format is incorrect",
    passwordValidate:"The password format is incorrect",
    tips: "No account yet？",
    register: "Go to register",
    forgetPassword: "Forget Password",
    emailPlaceholder: "Please enter email",
    passwordPlaceholder: "Please enter password",
  },
  forgetPassword: {
    title: "Forget Password",
    tips: "Enter the email address associated with your account",
    codePlaceholder: "Please enter the verification code",
    send: "confirm",
    getCode: "Get code",
    resend: "resend",
    originalPassword: "Original Password",
    newPassword: "Set new password",
    confirmPassword: "Confirm new password",
    originalPasswordPlaceholder: "Please enter the original password",
    newPasswordPlaceholder: "Please enter new password",
    confirmPasswordPlaceholder: "Please enter a new password again",
    confirmPasswordTips1: "Inconsistency between two entries of the new password",
    confirmPasswordTips2: "The new password is the same as the original password",
    sending: "Sending...",
  },
  register: {
    step1: "Fill in registration information",
    step2: "Fill out the application for admission",
    step3: "Entry review",
    title1: "Account Information",
    title2: "Company Information",
    title3: "Contact Information",
    username: "Username",
    lastNamePlaceholder: "Please enter last name",
    middleNamePlaceholder: "Please enter middle name",
    firstNamePlaceholder: "Please enter first name",
    email: "Register Email",
    code: "Email code",
    password: "password",
    passwordPlaceholder: "8 characters or more",
    companyName: "Company Name",
    companyShortName: "Company Short Name",
    companyAddress: "Company Address",
    qualifications: "Enterprise Qualification Certification",
    qualificationsImage: "Upload qualification certificate",
    mainBusiness: "Main Business",
    companyNamePlaceholder: "Please enter the full name of your company",
    companyShortNamePlaceholder: "Please enter your company name",
    companyAddressPlaceholder: "Please enter your company address",
    qualificationsPlaceholder: "Please select enterprise qualification certification",
    qualificationsImagePlaceholder: "Please upload your qualification certificate",
    mainBusinessPlaceholder: "Please enter your main business",
    tourismOrganization: "Tourism Organization",
    contactName: "Contact Name",
    alliance: "Tax Code",
    firstName: "First Name",
    lastName: "Last Name",
    contactEmail: "Contact Email",
    contactPhone: "Contact Phone",
    areaCode: "Area Code",
    emailPlaceholder: "Please enter the contact email",
    areaCodePlaceholder: "Please select area code",
    contactPhonePlaceholder: "Please enter phone",
    tips: "Only supports files in pdf, jpg and jpeg formats, with a size of 0-50MB",
    submit: "Submit application",
    headline: "Respected agents",
    reason: "Reasons for dismissal",
    revise: "Revise",
    viewNow: "View Now",
    auditTips1: "Your application has been successfully submitted, our staff will notify you of the result within 24 hours by e-mail, please wait patiently, thank you!！",
    auditTips2: "Please re-initiate your application according to the reasons for rejection！",
    approvalFailed: "The review of your inbound application has not been approved.",
  },
  router:{
    lineProducts:'Line Products',
    productOrders:'Product Orders',
    productDetail:'Product Detail',
    productBooking:'Product Booking',
    orderDetail:"Order Details",
    operationLog:"Operation Log",
    oderPayment:'Order Payment',
  },
  orderCenter:{
    orderID:"Order ID",
    orderInformation:"Order Information",
    orderInformationPlaceholder:"Contact Name, Email, Mobile Number",
    responsibleSales:"Responsible Sales",
    responsibleSalesPlaceholder:"Responsible Sales Name Or Email",
    orderTime:"Order Time",
    touristName:"Tourist Name",
    signingTime:"Signing Time",
    backTime:"Back Time",
    orderStatus:"Order Status",
    paymentStatus:"Payment Status",
    ToBeConfirmed:"To be confirmed",
    confirmed:"Confirmed",
    toBeSigned:"To Be Signed",
    signed:"Signed",
    waitingToTravel:"Waiting To Travel",
    traveling:"Traveling",
    cancelled:"Cancelled",
    done:"Done",
    unpaid:"Unpaid", //支付状态
    partialPayment:"Partial Payment",
    fullyPaid:"Fully Paid",
    numberOfTravelers:"Number Of Travelers",
    adults:"Adults",
    children:"Children",
    senior:"Senior",
    orderAmount:"Order Amount",
    orderContact:"Order Contact",
    amountReceivable:"Amount Receivable",//* */
    amountReceived:"Amount Received",
    unpaidAmount:"Unpaid Amount",
    viewDetails:"View Details",
    confirmSigning:"Confirm Signing",
    orderPayment:"Order Payment",
    packageType:"Package Type",
    orderInformation1:"Order Information",
    agent:"Agent",
    contactName:"Contact Name",
    contactEmail:"Contact Email",
    resourceInformation:"Resource Information", 
    orderRemarks:"Order Remarks",
    paymentRecord:"Payment Record",
    priceDetails:"Price Details",
    touristInformation:"Tourist information",
    priceType:"Price Type",
    count:"Count",
    totalPrice:"Total Price",
    totalCommission:"Total Commission",
    addANote:"Add Remarks",
    remarks:"Remarks",
    filledBy:"Filled by",
    fillInTime:"Fill in time",
    TotalAmountPaid:"Total amount paid",
    paymentAmount:"Payment Amount",
    paymentTime:"Payment Time",
    receiptCompany:"Receipt Company",
    paymentOrderNumber:"Payment Order Number",
    status:"Status",
    subscriberTime:"Subscriber/Time",
    totalPayableAmount:"Total payable amount",
    amountDetails:"Breakdown Of Amounts",
    serialNumber:"No.",
    surnameFirstName:"Name",
    gender:"Gender",
    male:"Male",
    female:"Female",
    email:"E-mail",
    phoneNumber:"Phone Number",
    nationality:"Nationality",
    passportNo:"Passport No.",
    passportValidity:"Passport Validity",
    birthDate:"Birth Date",
    paymentRules:"Payment Rules",
    deposit:"Deposit",
    final:"Final",
    fullPayment:"Full Payment",
    paymentDeadline:"Payment Deadline",
    confirmSheetStatus:{
      toBeSent:"To Be Sent",
      toBeSigned:"To Be Signed",
      signed:"Signed"
    },
    //订单支付页面
    remittanceAmount:"Remittance Amount",
    dollar:"$",
    currentOrderPayable:"Current order payable",
    canBeDividedInto:"Can be divided into",
    payments: "payments",
    paid:"Paid",
    notPaid:"Not Paid",
    minimumPayment:"This minimum payment",
    remittanceInformation:"Remittance Information",
    withinTheUnitedStates: "Within the United States",
    copyRemittanceInformation:"Copy remittance information",
    routingNumber: "Route number",
    accountName: "Account Name",
    payeesAddress: "Payee's address",
    receivingBank:"Receiving Bank",
    receivingAccount:"Receiving Account",
    receivingDate:"Remittance Date",
    remittanceVoucher:"Remittance Voucher",
    remittanceVoucherPlaceholder:"Please upload proof of payment",
    datePlaceholder:"Please select the time of remittance",
    operationContent:"Operation Content",//操作记录
    operationTime:"Operation Time",//操作记录
    operatorEmail:"Operator/E-mail",
    payNow: "Pay Now",
    cancelOrder: "Cancel Order",
    remainingPaymentTime: "Remaining payment time",
    reasonForCancellation: "Reason for cancellation",
    uploadTips: "Please choose .pdf / .jpg / .jpeg format files and keep the file size within 50MB”",
    clickUpload: "Click to upload",
    payAmountPlaceholder1: "Please enter the correct number",
    payAmountPlaceholder2: "The amount cannot be less than",
    payAmountPlaceholder3: "The amount cannot be greater than",
  },
  productCenter: {
    numberDays: "Number Days",
    productTags: "Product Tags",
    selected: "Selected",
    search: "Search",
    searchPlaceholder: "Departure City,Via City,Days Nights, Ends City",
    showCommission: "Show Commission",
    hiddenCommission: "Hidden Commission",
    earliestDeparture: "Earliest Departure",
    latestDeparture: "Latest Departure",
    more: "More",
    close: "Close",
    tips1: "Matching for you",
    tips2: "Products",
    commission: "Commission",
    share: "Share",
    checkDetails: "Check Details",
    commissionLabelHigh: "Commission Low -> High",
    commissionLabelLow: "Commission High -> Low",
    priceLabelHigh: "Price Low -> High",
    priceLabelLow: "Price High -> Low",
    dateLabelHigh: "Date Near -> Far",
    dateLabelLow: "Date Far -> Near",
    daysLabelHigh: "Days Less -> More",
    daysLabelLow: "Days More -> Less",
    total: "Total",
    items: "items",
    href: "href",
    copySuccess: "Copy Success",
    copyError: "Copy Error",
    downText: "Download itinerary file",
    from: "From",
    person: "/ person",
    dateTips: "The following prices are based on 1 adult. There may be a delay in pricing, please refer to the time of ordering",
    packageName: "Package Name",
    stockpile: "Stockpile",
    booking: "Booking",
    basePrice: "Base Price",
    tablesLabel: {
      name: "Name",
      introduction: "Introduction",
      price: "Price"
    },
    adult: "Adult",
    child: "Child",
    singleRoomDifference: "Single room difference",
    additionalFees: "Additional Fees",
    features: "Features",
    tourIntroduction: "Tour Introduction",
    feeDescription: "Fee Description",
    inclusiveOf: "Inclusive Of",
    feeDoesNotInclude: "Fee does not include",
    bookingInformation: "Booking Information",
    cancellationPolicy: "Cancellation Policy",
    kindTips: "Kind tips",
    learnMore: "More",
    stow: "Stow",
    breakfast: "Breakfast",
    lunch: "Lunch",
    dinner: "Dinner",
    agentInformation: "Agent Information", 
    contactEmail: "Contact Email", 
    contactName: "Contact Name",
    formLabel: {
      name: "Name",
      firstName: "First Name",
      middleName: "Middle Name",
      lastName: "Last Name",
      firstNamePlaceholder: "Please enter your first name",
      middleNamePlaceholder: "Please enter your middle name",
      lastNamePlaceholder: "Please enter your last name",
      gender: "Gender",
      genderPlaceholder: "Please select gender",
      man: "man",
      woman: "woman",
      transgenderMale: "Transgender male",
      transgenderFemale: "Transgender female",
      nonBinary: "Non-binary",
      genderqueer: "Genderqueer",
      intersex: "Intersex",
      uncharted: "Uncharted",
      email: "Email",
      emailPlaceholder: "Please enter your email address",
      phoneNumber: "Phone Number",
      phoneNumberPlaceholder: "Please enter phone number",
      phoneNumberPlaceholder1: "Incorrectly formatted cell phone number",
      areaCodePlaceholder: "Please select an area code",
      international: "international",
      nationality: "Nationality",
      nationalityPlaceholder: "Please enter your nationality",
      passportNo: "Passport No.",
      passportPlaceholder: "Please enter your Passport No.",
      passportPlaceholder1: "Incorrectly formatted passport number",
      passportValidity: "Passport validity period",
      passportValidityPlaceholder: "Please select passport validity period",
      visa: "Visa",
      visaPlaceholder: "Do you have a visa? Which country's?",
      birthday: "Birthday",
      birthdayPlaceholder: "Please select your date of birth",
      birthdayTips1: "Date of birth too large",
      birthdayTips2: "Date of birth too small",
    },
    registrationDeadline: "Registration Deadline",
    paymentRules: "Payment Rules",
    valueAddedItems: "Value-added items",
    totalPrice: "total",
    numbers: "Numbers",
    subtotal: "Subtotal",
    costBreakdown: "Cost breakdown",
    totalOrders: "Total orders",
    totalCommissions: "Total commissions",
    payable: "payable",
    submit: "Submit",
    next: "Next, fill in guest information",
    showMore: "show more",
    checkIn: "Check-in",
    dialogTitle: "Value-added program details",
    lostStock1: "enough",
    lostStock2: "Only",
    lostStock3: "left",
    noDateTips: "No tour dates on the same day",
    secondaryConfirmation: "Secondary confirmation",
    backTip1: "Back to previous step, view product information",
    backTip2: "Return to the previous step and change the number of people",
    bookingTip: "The number of visitors cannot be 0",
    documentation: "Itinerary documents",
  }
};