import { createRouter, createWebHistory } from "vue-router"
import ICON_ORDER from '@/assets/images/icon-order.png'
import ICON_LINE from '@/assets/images/icon-line.png';

const Layout = () => import("@/layout/index.vue")

/** 常驻路由 */
export const constantRoutes = [
  {
    path: "/403",
    component: () => import("@/views/error-page/403.vue"),
    meta: {
      hidden: true
    }
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404.vue"),
    meta: {
      hidden: true
    },
    alias: "/:pathMatch(.*)*"
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      hidden: true
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register/index.vue"),
    meta: {
      hidden: true
    }
  }
]

/**
 * 动态路由
 * 用来放置有权限 (Roles 属性) 的路由
 * 必须带有 Name 属性
 */
export const asyncRoutes = [
  {
    path: "/",
    component: Layout,
    redirect:"/product/list",
    meta: {
      hidden: false,
      title: 'router.lineProducts',
    },
    children: [
      {
        path: 'product/list',
        name: 'ProductList',
        component: () => import('@/views/Product/List.vue'),
        meta: {
          title: 'router.lineProducts',
          hidden: false,
          icon: ICON_LINE,
        }
      },
      {
        path: 'product/detail/:product_no',
        name: 'ProductDetail',
        component: () => import('@/views/Product/Detail.vue'),
        meta: {
          title: 'router.productDetail',
          hidden: true,
          icon: ICON_LINE,
        }
      },
      {
        path: 'product/booking',
        name: 'ProductBooking',
        component: () => import('@/views/Product/Booking.vue'),
        meta: {
          title: 'router.productBooking',
          hidden: true,
          icon: ICON_LINE,
        }
      },
      {
        path: 'product/visitor-info',
        name: 'VisitorInfo',
        component: () => import('@/views/Product/VisitorInfo.vue'),
        meta: {
          title: 'router.productBooking',
          hidden: true,
          icon: ICON_LINE,
        }
      },
    ]
  },
  {
    path: "/order",
    name: "Order",
    component: Layout,
    meta: {
      // hidden: false,
      title: 'router.productOrders',
    },
    redirect:"/order/list",
    children: [
      {
        path: "list",
        component: () => import("@/views/Order/List.vue"),
        name: "OrderList",
        meta: {
          title: 'router.productOrders',
          hidden: false,
          icon: ICON_ORDER,
        }
      },
      {
        path: "detail",
        component: () => import("@/views/Order/Detail.vue"),
        name: "OrderDetail",
        redirect:"/order/detail/order-detail",
        meta: {
          title: "router.orderDetail",
          roles: ["admin", "editor"], // 或者在子导航中设置角色
          hidden: true
        },
        children:[
          {
            path:"order-detail",
            name:"OrderDetailInfo",
            component: () => import("@/views/Order/OrderDetail.vue"),
            meta: {
              title: "router.orderDetail",
              roles: ["admin", "editor"], // 或者在子导航中设置角色
              hidden: false
            },
          },
          {
            path:"operation-log",
            name:"OperationLog",
            component: () => import("@/views/Order/OperationLog.vue"),
            meta: {
              title: "router.operationLog",
              roles: ["admin", "editor"],
              hidden: false
            },
          },

        ]
      },
      {
        path: "/order/payment",
        component: () => import("@/views/Order/OrderPaymentPage.vue"),
        name: "OrderPayment",
        meta: {
          title: 'router.oderPayment',
          hidden: true,
          icon: ICON_ORDER,
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
})

/** 重置路由 */
export function resetRouter() {
  // 注意：所有动态路由路由必须带有 Name 属性，否则可能会不能完全重置干净
  try {
    router.getRoutes().forEach((route) => {
      const { name, meta } = route
      if (name && meta?.roles.length) {
        router.hasRoute(name) && router.removeRoute(name)
      }
    })
  } catch (error) {
    // 强制刷新浏览器也行，只是交互体验不是很好
    window.location.reload()
  }
}

export default router
